* {
  margin: 0;
  box-sizing: border-box;
  padding: 0;
  font-family: "Satoshi", sans-serif;
}
a {
  text-decoration: none;
  color: white;
}
.front-page{
  position: relative;
}

/* NAVBAR */
/* body {
  margin: 0;
  padding: 0;
  display: flex;
  justify-content: center;
  align-items: center;
  height: 100%;
} */

@import url("https://fonts.cdnfonts.com/css/catellos-demo");
@import url("https://fonts.cdnfonts.com/css/Sansita");
@import url("https://fonts.cdnfonts.com/css/inter");

.nav {
  width: 45%;
  /* background-color: red; */
  /* border-radius: 50px; */
  /* border: 1px solid #151515; */
  height: 72px;
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 1rem;
  top: 30px;
  position: relative;
  margin: 0 auto;
  /* box-shadow: 0px 4px 30px rgba(0, 0, 0, 0.1); */
}
.nav-language-image {
  width: 20px; /* Adjust the width as needed */
  height: 20px; /* Adjust the height as needed */
}
.tape {
  width: 100%;
  height: 150px;
  display: flex;
  color: white;
  align-items: center;
  justify-content: center;
  font-size: 14px;
  background: #151515;
}
.header-title {
  font-weight: 400;
  font-size: 13px
}



@media screen and (max-width: 720px) {
  .header-title {
    font-weight: 400;
    font-size: 13px;
    width: 80%;
    text-align: center;
  }
}

.author {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 10px;
  margin-top: 10px;
}

.nav-container {
  display: flex;
  justify-content: center;
  align-items: center;
  width: 100%;
  padding: 0px 20px 0px;
  max-width: 1500px;
}

.logo {
  height: 40px;
}
.nav-search {
  /* width: 15%; */
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* float: right; */
}

.nav-right {
  /* width: 15%; */
  gap: 10px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  /* float: right; */
}

.nav-search input {
  border-radius: 5px;
  border: none;
  outline: none;
  height: 40px;
  width: 60%;
  padding: 20px;
  box-shadow: 0px 2px 5px #cecece;
}
.nav-search input:focus {
  outline: rgba(91, 91, 91, 0.2) solid 3px;
  border: #15151545 solid 1px;
}
.nav-links {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 0 auto;
}
.nav-links-center {
  display: flex;
  gap: 20px;
  justify-content: center;
  margin: 0 auto;
}
.nav-icon {
  font-size: 14px;
  color: rgb(172, 172, 172);
  cursor: pointer;
  margin-top: 7px;
  justify-content: center;
  transition: color ease-in-out 0.2s;
}
.nav-link {
  font-size: 14px;
  color: rgb(172, 172, 172);
  cursor: pointer;
  margin-top: 7px;
  justify-content: center;
  transition: color ease-in-out 0.2s;
}
.nav-link:hover {
  color: #151515;
}

.nav-link-img {
  font-size: 20px;
  color: rgb(172, 172, 172);
  cursor: pointer;
  margin-top: 5px;
  transition: color ease-in-out 0.2s;
}

.nav-logo {
  font-size: 20px;
  color: rgb(172, 172, 172);
  cursor: pointer;
  margin-top: 5px;
  transition: color ease-in-out 0.2s;
}

.nav-button {
  width: 100%;
  padding: 20px 10px;
  border-radius: 10px;
  height: 40px;
  font-weight: 500;
  border: rgba(91, 91, 91, 0.2) 1px solid;
  background-color: #e4e4e4;
  cursor: pointer;
  display: flex;
  align-items: center;
  justify-content: center;
  gap: 5px;
  color: black;
}
.nav-button:hover {
  opacity: 0.7;
}
/* Style for the dropdown container */
.button-container {
  position: relative;
}

/* Style for the dropdown icon */
.button-container .nav-button svg {
  font-size: 18px;
  margin-left: 5px;
}

/* Style for the dropdown */
.dropdown {
  position: absolute;
  margin-top: 20px;
  top: 100%;
  left: 0;
  background-color: #ffffff;
  /* border: 1px solid #ccc; */
  border-radius: 15px;
  box-shadow: 0px 2px 4px rgba(0, 0, 0, 0.1);
  z-index: 100;
  width: 100%;
  padding: 10px;
  color: #333;
}

/* Style for the dropdown items */
.dropdown-item {
  padding: 10px 10px;
  /* border-bottom: 1px solid #ccc; */
  display: flex;
  width: 100%;
  border: none;
  color: #333;
  background-color: #ffffff;
  text-align: left;
  cursor: pointer;
  gap: 10px;
  align-items: center;
  transition: background-color 0.3s ease;
}

/* Hover effect for dropdown items */
.dropdown-item:hover {
  background-color: #f5f5f5;
  border-radius: 10px;
}

/* Last dropdown item without border */
.dropdown-item:last-child {
  border-bottom: none;
}

/* end button component styles */
/* .nav-button:hover svg {
  color: rgb(255, 107, 131);
} */
.open-side-nav {
  border: none;
  color: white;
  background-color: #151515;
  width: 30px;
  height: 30px;
  cursor: pointer;
  border-radius: 5px;
  display: none;
}
.open-side-nav:hover {
  opacity: 0.7;
}
/* HERO */

.section-container {
  display: flex;
  align-items: center;
  flex-direction: column;
  margin: 0 auto;
  gap: 30px;
  width: 100%;
  padding: 110px 24px;
  max-width: 1500px;
}

.section-container h1 {
  font-size: 48px;
  width: 60%;
  font-weight: 500;
  text-align: center;
  color: #151515;
  /* font-family: "Catellos Demo"; */
  font-family: "Sansita", sans-serif;
}

.section-container h6 {
  font-weight: 400;
}

.section-container span {
  /* font-weight: 400; */
  font-family: "Sansita", sans-serif;
}
.section-text {
  color: #898989;
  font-size: 14px;
  font-weight: 400px;
  font-family: "Inter", sans-serif;
  line-height: 1.5;
  text-align: center;
  /* width: 60%; */
  /* max-width: 750px; */
}
.herophone-container {
  display: flex;
  /* width: 100%; */
  justify-content: center;
  /* gap: 15px; */
  position: fixed;
  bottom: 0px;
  /* top: 20px; */
  /* margin-top: 40px; */
}

.herophone-footer img {
  width: 350px;
  /* max-width: 350px; */
  height: auto;
  display: block;
}

.section-container button {
  font-size: 12px;
  width: 100%;
  padding: 10px;
  /* cursor: pointer; */
  font-weight: 500;
  justify-content: center;
  transition: 0.2s ease-in-out all;
}
.herophone-container button {
  font-size: 12px;
  width: 100%;
  height: 47px;
  padding: 20px;
  /* border: none; */
  border-radius: 50px;
  cursor: pointer;
  display: flex;
  gap: 10px;
  font-weight: 500;
  justify-content: center;
  /* width: 200px; */
  align-items: center;
  color: white;
  transition: 0.2s ease-in-out all;
  background: #151515;
}
.herophone-container button:hover {
  opacity: 0.7;
}

.herophone-footer {
  display: flex;
  bottom: 30px;
  /* margin-right: 20; */
}

/* Default style for the footer image */
.footer-image {
  width: 100%; /* Default width for the image */
  /* z-index: 9999; */
}

/* Media query for smaller devices (e.g., mobile) */
@media only screen and (max-width: 768px) {
  .footer-image {
    width: 80%; /* Adjust the width for smaller devices */
    /* padding: 50px; */
    /* margin: 0 auto; */
  }
}

/* Media query for larger devices (e.g., desktop) */
@media only screen and (min-width: 769px) {
  .footer-image {
    width: 50%; /* Adjust the width for larger devices */
    /* padding: 50px; */
    /* bottom: 100px; */
    /* margin: 0 auto; */
  }
}


.open-on-web {
  font-size: 0.8rem;
  height: 3rem;
  display: none;
  border: none;
  border-radius: 5px;
  cursor: pointer;
  font-weight: 500;
  width: 80%;
  color: rgb(255, 28, 43);
  transition: 0.2s ease-in-out all;
  background: rgba(243, 63, 63, 0.192);
}

.jwt-features {
  display: flex;
  flex-wrap: wrap;
  padding: 24px;
  width: 100%;
  justify-content: space-between;
  margin: 0 auto;
}

.jwt-feature {
  padding: 20px;
  width: 30%;
  border: 1px solid rgba(205, 205, 205, 0.719);
  margin-top: 24px;
  font-size: 14px;
  gap: 15px;
  border-radius: 10px;
  display: grid;
}
.jwt-feature div {
  width: 50px;
  height: 50px;
  background-color: #151515;
  border-radius: 50%;
  display: grid;
  place-items: center;
  color: white;
}

@media screen and (max-width: 720px) {
  .nav {
    height: 72px;
    width: 80%;
    border-bottom: 1px solid rgb(235, 235, 235);
  }
  .sponsor {
    display: none;
  }
  .tape {
    height: 100px;
  }
  .nav-link-img {
    display: none;
  }
  .nav-right {
    width: 50%;
  }
  .nav-right input {
    display: none;
  }
  .section-container {
    padding: 80px 0px;
  }
  .section-container h1 {
    font-size: 3rem;
    width: 80%;
  }
  .section-text {
    font-size: 1rem;
    width: 90%;
  }
  .jwt-feature {
    width: 48%;
  }
}

/* Landscape */
@media only screen and (min-device-width: 768px) and (max-device-width: 1024px) and (orientation: landscape) and (-webkit-min-device-pixel-ratio: 1) {
  .nav {
    height: 52px;
    width: 80%;
    border-bottom: 1px solid rgb(235, 235, 235);
  }
}

/* Landscape */
@media only screen 
  and (min-device-width: 320px) 
  and (max-device-width: 480px)
  and (-webkit-min-device-pixel-ratio: 2)
  and (orientation: landscape) {
    nav {
      height: 52px;
      width: 80%;
      border-bottom: 1px solid rgb(235, 235, 235);
    }
}

@media screen and (max-width: 450px) {
  .nav {
    height: 72px;
    width: 80%;
    border-bottom: 1px solid rgb(235, 235, 235);
  }
  .tape {
    font-size: 12px;
    height: 80px;
  }
  .nav-link-img {
    display: none;
  }
  .nav-right {
    width: 60%;
    justify-content: flex-end;
  }
  .nav-container a span {
    font-size: 14px;
  }
  .section-container h1 {
    font-size: 1.7rem;
    width: 80%;
  }
  .section-text {
    font-size: 0.8rem;
    width: 90%;
    margin-top: -20px;
  }
  .section-container {
    padding: 45px 0px;
  }
  
  .herophone-container button {
    font-size: 0.7rem;
    width: 120px;
    height: 3rem;
  }
  .open-on-web {
    display: block;
  }
  .nav-right .nav-button {
    display: none;
  }
  .nav-right span {
    display: none;
  }
  .nav-links {
    display: none;
  }
  .open-side-nav {
    display: block;
  }

  .jwt-feature {
    width: 100%;
  }

  .herophone-footer img {
    width: 250px;
    /* max-width: 150px; */
  }
}

/* @media screen and (min-width: 768px) {
  .herophone-container {
    display: grid;
    justify-content: center;
    position: relative;
  }
} */

@media screen and (max-width: 736px) {
  .herophone-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
  }
}

@media screen and (max-width: 600px) {
  .herophone-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
  }
}

@media screen and (max-width: 375px) {
  .herophone-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
  }
}

@media screen and (max-width: 414px) {
  .herophone-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
  }
}

@media screen and (max-width: 320px) {
  .herophone-container {
    display: flex;
    justify-content: center;
    position: fixed;
    bottom: 0px;
  }
}

.typing-text {
  font-size: 2rem;
  text-align: center;
  white-space: pre-line;
  /* margin-top: 50px; */
  opacity: 0;
  transform: translateY(20px);
  animation: fadeInUp 1s ease-in-out forwards;
}

.animate-typing {
  animation: typing 2s steps(30, end), fadeInUp 1s ease-in-out forwards;
}

@keyframes fadeInUp {
  0% {
    opacity: 0;
    transform: translateY(20px);
  }
  100% {
    opacity: 1;
    transform: translateY(0);
  }
}

@keyframes typing {
  from {
    width: 0;
  }
  to {
    width: 100%;
  }
}

/* Add this to your Section.css */
.highlight {
  animation: colorChange 2s infinite alternate; /* Animate color change */
}

@keyframes colorChange {
  from {
    /* first color */
    /* color: #e67fd8; */
    color: #4FAE5A;
  }
  to {
    /* second color */
    /* color: #6922d1; */
    color: #F3B304;
  }
}

.footer {
  position: fixed;
  bottom: 0;
  /* right: 0; */
  left: 150;
  /* left: 50%; */
  transform: translateX(-50%);
  /* width: 300px; */
  
  padding: 10px; /* Optional: Add padding */
}

/* button animation css */
.button-container {
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: space-between;
}

.left {
  position: fixed;
  left: 150px;
  /* top: 50%; */
  bottom: 45px;
  /* transform: translateY(-50%); */
  /* transform: translateY(calc(100% - 10px)); */
  /* transform: 'rotate(1.9deg)'; */
  /* width: 600px; */
}

.right {
  position: fixed;
  right: 160px;
  bottom: 45px;
  /* left: 50px; */
  /* right: 50px; */
  /* top: 50%; */

  /* transform: translateY(-50%); */
}

/*  */
/* .button-container.left {
  position: relative;
}

.button-container.left .motion-div {
  position: absolute;
  left: 0;
}

.button-container.left .motion-div:nth-child(n+5) {
  bottom: 80px;
}

.button-container.left .motion-div:nth-child(-n+4) {
  bottom: 0;
} */
.support-container {
  max-width: 1200px;
  margin: 0 auto;
  padding: 0 24px;
  min-height: 100vh;
  background: #fff;
}

.support-nav {
  display: flex;
  justify-content: space-between;
  align-items: center;
  padding: 16px 0;
  margin-bottom: 48px;
  border-bottom: 1px solid #e6e6e6;
}

.nav-left .brand {
  color: #0a2540;
  font-size: 15px;
  font-weight: 600;
  text-decoration: none;
}

.nav-right {
  display: flex;
  align-items: center;
  gap: 24px;
}

.nav-link {
  color: #425466;
  font-size: 14px;
  text-decoration: none;
  transition: color 0.2s ease;
}

.nav-link:hover {
  color: #0a2540;
}

.nav-link.sign-in {
  color: #635bff;
}

.support-content {
  max-width: 800px;
  margin: 0 auto;
  padding: 0 24px;
}

.search-section {
  margin-bottom: 64px;
  text-align: left;
}

.search-section h1 {
  font-size: 32px;
  font-weight: 600;
  color: #0a2540;
  margin-bottom: 24px;
}

.search-box {
  position: relative;
  margin-bottom: 32px;
}

.search-box input {
  width: 100%;
  padding: 12px 16px 12px 40px;
  font-size: 16px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  background: #f7fafc;
  transition: all 0.2s ease;
}

.search-box input:focus {
  outline: none;
  border-color: #635bff;
  background: #fff;
  box-shadow: 0 0 0 4px rgba(99, 91, 255, 0.1);
}

.search-icon {
  position: absolute;
  left: 12px;
  top: 50%;
  transform: translateY(-50%);
  color: #425466;
  font-size: 16px;
}

/* Contact Information Section */
.contact-info-section {
  margin-bottom: 64px;
}

.contact-info-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: #0a2540;
  margin-bottom: 32px;
}

.contact-cards {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(240px, 1fr));
  gap: 24px;
}

.contact-card {
  padding: 24px;
  background: #f7fafc;
  border-radius: 8px;
  text-align: center;
  transition: all 0.2s ease;
}

.contact-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.contact-card i {
  font-size: 24px;
  color: #635bff;
  margin-bottom: 16px;
}

.contact-card h3 {
  font-size: 18px;
  font-weight: 600;
  color: #0a2540;
  margin-bottom: 8px;
}

.contact-card p {
  color: #425466;
  font-size: 14px;
  margin-bottom: 4px;
}

.contact-card .response-time {
  color: #635bff;
  font-size: 12px;
  font-weight: 500;
}

/* FAQs Section */
.faqs-section {
  margin-bottom: 64px;
}

.faqs-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: #0a2540;
  margin-bottom: 32px;
}

.faq-categories {
  display: flex;
  flex-direction: column;
  gap: 48px;
}

.faq-category {
  background: #fff;
  border-radius: 12px;
}

.category-title {
  font-size: 20px;
  font-weight: 600;
  color: #0a2540;
  margin-bottom: 24px;
  padding-bottom: 12px;
  border-bottom: 2px solid #f0f0f0;
}

.faq-list {
  display: flex;
  flex-direction: column;
  gap: 16px;
}

.faq-item {
  border: 1px solid #E6E5E2;
  border-radius: 8px;
  /* margin-bottom: 16px; */
  overflow: hidden;
  transition: all 0.3s ease;
  cursor: pointer;
}

.faq-item:hover {
  border-color: #4FAE5A;
  /* box-shadow: 0 4px 12px rgba(79, 174, 90, 0.1); */
}

.faq-item.active {
  border-color: #4FAE5A;
  /* box-shadow: 0 4px 12px rgba(79, 174, 90, 0.15); */
}

.faq-question {
  padding: 20px;
  display: flex;
  justify-content: space-between;
  align-items: center;
  background: #fff;
}

.faq-question h4 {
  margin: 0;
  font-size: 16px;
  font-weight: 500;
  color: #151515;
}

.faq-question i {
  color: #4FAE5A;
  transition: transform 0.3s ease;
}

.faq-item.active .faq-question i {
  transform: rotate(180deg);
}

.faq-answer {
  padding: 0 20px;
  background: #fff;
  overflow: hidden;
}

.faq-answer p {
  margin: 0;
  padding: 0 0 20px;
  font-size: 14px;
  line-height: 1.6;
  color: #425466;
}

/* Help Articles Section */
.help-articles-section {
  margin-bottom: 64px;
}

.help-articles-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: #0a2540;
  margin-bottom: 32px;
}

.help-articles-grid {
  display: grid;
  grid-template-columns: repeat(auto-fit, minmax(280px, 1fr));
  gap: 24px;
}

.help-article-card {
  padding: 24px;
  background: #f7fafc;
  border-radius: 8px;
  text-decoration: none;
  transition: all 0.2s ease;
}

.help-article-card:hover {
  transform: translateY(-2px);
  box-shadow: 0 4px 6px rgba(0, 0, 0, 0.1);
}

.help-article-card .article-icon {
  width: 40px;
  height: 40px;
  background: rgba(99, 91, 255, 0.1);
  border-radius: 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 16px;
}

.help-article-card .article-icon i {
  font-size: 20px;
  color: #635bff;
}

.help-article-card h3 {
  font-size: 18px;
  font-weight: 600;
  color: #0a2540;
  margin-bottom: 8px;
}

.help-article-card p {
  color: #425466;
  font-size: 14px;
  line-height: 1.6;
  margin-bottom: 16px;
}

.help-article-card .read-more {
  color: #635bff;
  font-size: 14px;
  font-weight: 500;
}

/* Support Form Section */
.support-form-section {
  margin-bottom: 64px;
  padding: 32px;
  background: #F9F9F9;
  border-radius: 8px;
}

.support-form-section h2 {
  font-size: 24px;
  font-weight: 600;
  color: #0a2540;
  margin-bottom: 8px;
}

.support-form-section > p {
  color: #425466;
  font-size: 14px;
  margin-bottom: 32px;
}

.support-form {
  display: flex;
  flex-direction: column;
  gap: 24px;
}

.form-group {
  display: flex;
  flex-direction: column;
  gap: 8px;
}

.form-group label {
  font-size: 14px;
  font-weight: 500;
  color: #0a2540;
}

.form-group input,
.form-group textarea {
  padding: 12px;
  border: 1px solid #e6e6e6;
  border-radius: 6px;
  font-size: 14px;
  transition: all 0.2s ease;
}

.form-group input:focus,
.form-group textarea:focus {
  outline: none;
  border-color: #635bff;
  box-shadow: 0 0 0 4px rgba(99, 91, 255, 0.1);
}

.form-group textarea {
  resize: vertical;
  min-height: 120px;
}

.submit-button {
  padding: 12px 24px;
  background: #635bff;
  color: #fff;
  border: none;
  border-radius: 6px;
  font-size: 14px;
  font-weight: 500;
  cursor: pointer;
  transition: all 0.2s ease;
  align-self: flex-start;
}

.submit-button:hover {
  background: #524acc;
}

@media (max-width: 768px) {
  .support-container {
    padding: 0 16px;
  }

  .support-content {
    padding: 0 16px;
  }

  .search-section h1 {
    font-size: 28px;
  }

  .contact-cards {
    grid-template-columns: 1fr;
  }

  .help-articles-grid {
    grid-template-columns: 1fr;
  }

  .support-form-section {
    padding: 24px 16px;
  }

  .submit-button {
    width: 100%;
  }

  .faq-categories {
    gap: 32px;
  }

  .category-title {
    font-size: 18px;
    margin-bottom: 16px;
  }

  .faq-question h4 {
    font-size: 15px;
  }

  .faq-answer p {
    font-size: 13px;
  }
} 